import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { selectSiteUrl } from '../../features/siteUrl/siteUrlSlice'
import { Card } from 'antd'
import './carousel.css'

const { Meta } = Card

const Category = () => {
	const backendAPI = useSelector(selectSiteUrl)
	const [categories, setCategories] = useState([])

	const fetchData = async () => {
		try {
			const response = await fetch(`${backendAPI}/category`)
			const responseData = await response.json()
			setCategories(responseData)
			console.log(responseData)
			console.log(responseData)
		} catch (error) {
			console.log(error)
		}
	}

	useEffect(() => {
		fetchData()
	}, [])

	return (
		<div className='container'>
			<div className='row row-cols-lg-3 cat d-flex justify-content-center pb-4'>
				{categories.map((category) => (
					<div key={category.id} className='col category'>
						<Link to={`/category/${category.id}`} className='link'>
							<Card
								hoverable
								style={{ width: 350, marginBottom: 20 }}
								cover={<img alt={category.name} src={`${backendAPI}${category.image}`} />}
							>
								<Meta title={category.name} className='meta-title' />
							</Card>
						</Link>
					</div>
				))}
			</div>
		</div>
	)
}

export default Category
