import React from 'react'
import Carousel from './Carousel'
import Category from './Category'
import './carousel.css'
import Cards from './Cards'
import Features from './Features/Features'
import AboutUs from './AboutUs'

const AppBody = () => {
	return (
		<>
			<Carousel />
			<Category />
			<Features />
			<Cards />
			<AboutUs />
		</>
	)
}

export default AppBody
