import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	value: 'https://instrumentidarom.ru',
}

export const siteUrlSlice = createSlice({
	name: 'siteUrl',
	initialState,
	reducers: {
		setUrl: (state, action) => {
			state.value = action.payload
		},
	},
})

export const { setUrl } = siteUrlSlice.actions

export const selectSiteUrl = (state) => state.siteUrl.value

export default siteUrlSlice.reducer
