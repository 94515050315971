/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from 'react'
import { Carousel, Card } from 'antd'
import { Link } from 'react-router-dom'
import './carousel.css'
import Cookies from 'js-cookie'
import { useSelector } from 'react-redux'
import { selectSiteUrl } from '../../features/siteUrl/siteUrlSlice'

const contentStyle = {
	height: '270px',
	width: '100%',
}

const Cards = () => {
	const backendAPI = useSelector(selectSiteUrl)
	const [allCards, setAllCards] = useState([])

	const fetchData = async () => {
		const cookie = Cookies.get('city_id')
		try {
			const headers = {}
			if (Cookies.get('token')) {
				headers.Authorization = `Bearer ${Cookies.get('token')}`
			}
			const response = await fetch(`${backendAPI}/product?status=AC${cookie ? `&city=${cookie}` : ''}`, {
				headers,
			})
			const data = await response.json()
			setAllCards(data)
		} catch (error) {
			console.log(error)
		}
	}

	useEffect(() => {
		fetchData()
	}, [])

	const productImages = (images) => {
		return images.map((image) => (
			<div key={image.id}>
				<img style={contentStyle} src={`${backendAPI}${image.img}`} />
			</div>
		))
	}

	const card = allCards.map((el, index) => {
		return (
			<div className='gutter-example' key={index}>
				<Link to={`/product/${el.id}`} className='link'>
					<Card hoverable style={{ width: 350, marginBottom: 20 }}>
						<Carousel autoplay autoplaySpeed={Math.random() * (6000 - 3000) + 3000}>
							{productImages(el.images)}
						</Carousel>
						<Card.Meta
							className='pt-3'
							title={
								<a href={`/product/${el.id}`} className='card__title'>
									{el.name}
								</a>
							}
						/>
						<p className='card-text'>
							{el.is_lower_bound ? 'от ' : ''}
							{el.price}
							{el.price_suffix ? ' ' + el.price_suffix : ''}
						</p>
					</Card>
				</Link>
			</div>
		)
	})

	const onEmptyCity = () => {
		return <h3>К сожалению, товаров нету.</h3>
	}

	return (
		<div className='container px-4 pb-5 pt-4'>
			<div class='title pt-5'>
				<h2>Новинки</h2>
			</div>
			<div className={allCards.length ? 'row  row-cols-1 row-cols-lg-3 col-md-auto' : 'empty__title'}>
				{allCards.length ? card : onEmptyCity()}
			</div>
		</div>
	)
}

export default Cards
