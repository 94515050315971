/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect } from 'react'
import { AutoComplete, Input, Form, Button, Space, Menu, Dropdown } from 'antd'
import { useState } from 'react'
import './AppHeader.css'
import instrdLogo from './img/logoRedLetters.jpg'
import { Link } from 'react-router-dom'
import Cookies from 'js-cookie'
import { useSelector } from 'react-redux'
import { selectSiteUrl } from '../../features/siteUrl/siteUrlSlice'

const searchResult = async (query, backendAPI) => {
	const response = await fetch(
		`${backendAPI}/search/?name=${query}${Cookies.get('city_id') ? '&city=' + Cookies.get('city_id') : ''}`
	)
	const data = await response.json()
	return data.map((product) => ({
		value: product.name,
		key: product.id,
		label: (
			<div
				style={{
					display: 'flex',
					justifyContent: 'space-between',
				}}
			>
				<span>
					<a href={`/product/${product.id}`} target='_blank' rel='noopener noreferrer'>
						{product.name}
					</a>
				</span>
			</div>
		),
	}))
}

const LowerHeader = () => {
	const [options, setOptions] = useState([])
	const [searchInput, setSearchInput] = useState('')
	const [catalogItems, setCatalogItems] = useState([])

	const backendAPI = useSelector(selectSiteUrl)

	useEffect(() => {
		const fetchCatalogItems = async () => {
			try {
				const response = await fetch(`${backendAPI}/category`)
				const data = await response.json()
				setCatalogItems(data)
				console.log('всё ок', { catalogItems })
			} catch (error) {
				console.error('Ошибка при получении данных каталога:', error)
			}
		}

		fetchCatalogItems()
	}, [backendAPI])

	const handleSearch = async (value) => {
		try {
			const result = await searchResult(value, backendAPI)
			setOptions(value ? result : [])
			setSearchInput(value)
			console.log(options)
		} catch (error) {
			console.log(error)
		}
	}

	const menu = (
		<Menu>
			{catalogItems.map((item) => (
				<Menu.Item key={item.id}>
					<a href={`/category/${item.id}`}>{item.name}</a>
				</Menu.Item>
			))}
		</Menu>
	)

	const onSelect = (value) => {
		console.log('onSelect', value)
	}

	const onChange = (value) => {
		console.log(value)
	}

	return (
		<div className='container'>
			<header className='py-3 mb-2 mt-1'>
				<div className='site-header__middle flex-wrap'>
					<div className='col-md-12 col-12 pb-4 upper-group-header'>
						<div className='col-md-3 col-12 site-header__logo'>
							<a
								href='/'
								className='d-flex align-items-center mb-3 mb-lg-0 me-lg-auto text-dark text-decoration-none'
							>
								<img src={instrdLogo} />
							</a>
						</div>
						<div className='col-md-8 col-7'>
							<div className='site-header__contact'>
								<div className='site-header__contact-item'>
									<i className='fa fa-clock'></i>
									<div className='header-feature-caption'>
										<div className='header-feature-title'>Приём звонков:</div>
										<div className='header-feature-title'>ПН-ПТ 9:00-18:00</div>
										<div className='header-feature-title'>СБ-ВС ВЫХОДНОЙ</div>
									</div>
								</div>
								<div className='site-header__contact-item'>
									<i className='fa fa-phone'></i>
									<div className='header-feature-caption'>
										<a className='telephone-number' href='tel:+73952653528'>
											<div className='header-feature-title'>+7 (3952) 43-83-00</div>
										</a>
									</div>
								</div>
								<div className='site-header__contact-item'>
									<i class='fas fa-shipping-fast'></i>
									<div className='header-feature-caption'>
										<div className='header-feature-title'>Доставка</div>
										<p className='header-feature-sub-title'>от 700р</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className='col-md-12 col-12 catalog__area'>
						<div className='col-md-3 col-12 catalog__area__unit catalog'>
							<Dropdown overlay={menu} placement='bottomLeft'>
								<Button
									style={{ color: 'white', backgroundColor: '#e11e26', borderColor: 'white' }}
									className='mb-2 mb-md-0 catalog__button'
								>
									Каталог
								</Button>
							</Dropdown>
						</div>
						<div className='col-md-8 col-12 catalog__area__unit search'>
							<Form className='search_panel'>
								<AutoComplete
									dropdownMatchSelectWidth={252}
									className='search_panel'
									options={options}
									onSelect={onSelect}
									onSearch={handleSearch}
									onChange={onChange}
								>
									<Space.Compact>
										<Input size='large' placeholder='Поиск по товарам' />
										<Link to={`/search/${searchInput}`}>
											<Button className='searchButton' type='primary'>
												Найти
											</Button>
										</Link>
									</Space.Compact>
								</AutoComplete>
							</Form>
						</div>
					</div>
				</div>
			</header>
		</div>
	)
}

export default LowerHeader
