/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import './carousel.css'

const Carousel = () => {
	return (
		<div id='myCarousel' className='carousel slide container pb-5' data-bs-ride='carousel'>
			<div className='carousel-inner'>
				<div className='carousel-item active image-banner banner2'>
					<div className='container'>
						<div className='carousel-caption text-end'></div>
					</div>
				</div>
				<div className='carousel-item image-banner banner1'>
					<div class='container'>
						<div className='carousel-caption text-start'></div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Carousel
