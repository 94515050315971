import './carousel.css'

const AboutUs = () => {
	return (
		<div class='container'>
			<div class='row' className='about-us-group'>
				<div class='col-md-8 col-12'>
					<div class='title'>
						<h2>О компании</h2>
					</div>
					<p>
						ООО "Инструменты даром" ваш надежный поставщик в мире инструментов и строительного оборудования.
						Благодаря многолетнему опыту, наша компания успешно сотрудничает с многочисленными
						предприятиями, расположенными от Урала до Дальнего Востока, предоставляя им всё необходимое для
						эффективной работы. Мы гарантируем высокое качество нашей продукции и оперативность в выполнении
						заказов.
					</p>
				</div>

				<div class='col-md-4 col-12 d-flex align-items-center'>
					<img
						src='https://storage.yandexcloud.net/harvey-rus.ru/resize_cache/871709/6d983eee43dd17d641ee6fc0779d4b40/iblock/30f/30fc6abfb58442fd6f6d8a4de168b151/jib_mc1218vda.png'
						class='img-fluid'
						alt='Инструменты'
					/>
				</div>
			</div>
		</div>
	)
}

export default AboutUs
