const Features = () => {
	return (
		<div id='features' class='features'>
			<div class='container'>
				<div class='title'>
					<h2>Почему стоит выбрать нас?</h2>
				</div>
				<div class='content'>
					<div class='row'>
						<div class='col-md-6'>
							<div class='content-box'>
								<div class='icon'>
									<i class='fas fa-th'></i>
								</div>
								<div class='text'>
									<h4>Ассортимент</h4>
									<p>
										У нас вы найдёте широкий ассортимент различной продукции от ведущих
										производителей.
									</p>
								</div>
							</div>
						</div>
						<div class='col-md-6'>
							<div class='content-box'>
								<div class='icon'>
									<i class='fas fa-tag'></i>
								</div>
								<div class='text'>
									<h4>Цена</h4>
									<p>Выгодные цены благодаря прямым поставкам от производителей.</p>
								</div>
							</div>
						</div>
					</div>
					<div class='row'>
						<div class='col-md-6'>
							<div class='content-box'>
								<div class='icon'>
									<i class='fas fa-shield-alt'></i>
								</div>
								<div class='text'>
									<h4>Гарантия</h4>
									<p>Гарантийное обслуживание приобретённого оборудования.</p>
								</div>
							</div>
						</div>
						<div class='col-md-6'>
							<div class='content-box'>
								<div class='icon'>
									<i class='fas fa-star'></i>
								</div>
								<div class='text'>
									<h4>Качество</h4>
									<p>
										Мы сотрудничаем только с лучшими производителями оборудования, что даёт
										уверенность в надёжности оборудования.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Features
