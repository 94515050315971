import React from 'react'

const HelpPage = () => {
	return (
		<div className='container'>
			<p>
				<strong>Помощь</strong>
			</p>
			<p>За помощью можно обратиться написав письмо на почту vadim.ermakov-89@mail.ru</p>
		</div>
	)
}

export default HelpPage
