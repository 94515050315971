import React from 'react'

const AboutPage = () => {
	return (
		<div className='container'>
			<p>
				<strong>О проекте</strong>
			</p>
			<p>
				Данное приложение разработано в качестве дипломной работы студентом 4ого курса НВГУ Ермаковым Вадимом
				Константиновичем.&nbsp;
				<br />
				<br />В стеке технологий используется Django, React, Redux.
			</p>
			<p></p>
		</div>
	)
}

export default AboutPage
