/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import './ProductPage.css'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import { useSelector } from 'react-redux'
import { selectSiteUrl } from '../../features/siteUrl/siteUrlSlice'
import { Carousel } from 'react-responsive-carousel'

const ProductPage = () => {
	const backendAPI = useSelector(selectSiteUrl)
	const [product, setProduct] = useState(null)
	const { id } = useParams()

	useEffect(() => {
		fetch(`${backendAPI}/product/${id}`)
			.then((response) => response.json())
			.then((data) => setProduct(data))
			.catch((error) => console.log(error))
	}, [id])

	if (!product) {
		return <p>Loading...</p>
	}

	return (
		<>
			<div className='container'>
				<div className='row'>
					<div className='col-lg-8'>
						<h1>{product.name}</h1>
						<div className='outer'>
							<Carousel showStatus={false} showIndicators={false}>
								{product.images.map((image) => (
									<div key={`${backendAPI}${image.img}`}>
										<img src={`${backendAPI}${image.img}`} />
									</div>
								))}
							</Carousel>
						</div>
					</div>
					<div className='col-md-4 col-11'>
						<h3>Описание</h3>
						<div>
							<p>{product.description}</p>
						</div>
						<h3>Наличие: Под заказ</h3>
						<div class='price-container'>
							<h1>
								{product.is_lower_bound ? 'От' : ''} {product.price} {product.price_suffix}
							</h1>
						</div>
					</div>
				</div>
				{product.features.length > 0 ? (
					<div className='pt-3'>
						<h3>Характеристики</h3>
						<div className='col-lg-8'>
							<div className='row'>
								{product.features.map((feature) => (
									<div className='col-lg-6' key={feature.name}>
										<p>
											{feature.name}: {feature.value}
										</p>
									</div>
								))}
							</div>
						</div>
					</div>
				) : (
					''
				)}
			</div>
		</>
	)
}

export default ProductPage
